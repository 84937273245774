import React from "react";
import Spinner from "react-bootstrap/Spinner";

export default function Loading() {
  return (
    <div className="align-self-center justify-self-center">
      <Spinner animation="border" variant="secondary" role="status">
        <span className="sr-only">Loading...</span>
      </Spinner>
    </div>
  );
}

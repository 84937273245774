import React, { Suspense, lazy } from "react";
import { Route, Routes } from "react-router-dom";

import Loading from "./common/Loading";
const Home = lazy(() => import("./common/Home"));
const TermsOfService = lazy(() => import("./common/TermsOfService"));
const PrivacyPolicy = lazy(() => import("./common/PrivacyPolicy"));
const Support = lazy(() => import("./common/Support"));
const EmergencyView = lazy(() => import("./emergency/Emergency"));
const ActivityView = lazy(() => import("./activity/ActivityTracking"));
const NotFound = lazy(() => import("./common/NotFound"));

export default function AppRoutes() {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="support" element={<Support />} />
        <Route path="e/:activity_id" element={<EmergencyView />} />
        <Route path="a/:activity_id" element={<ActivityView />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppRoutes from "./Routes";
import ErrorBoundary from "./common/ErrorBoundary";
import { AppContext } from "./libs/contextLib";
// import AlertMessage from "./common/AlertMessage";

import {ReactComponent as LogoOnly} from './static/LogoOnly.svg';
import {ReactComponent as LogoFull} from "./static/LogoFull.svg"

import "./App.css"

function App() {
  let navigate = useNavigate();
  return (
    <>
      <Link to="/" className="nav-logo">
        {/* <LogoOnly />  */}
        <LogoFull />
      </Link>
      <ErrorBoundary>
        <AppContext.Provider value={{ navigate }}>
          <div className="main-container">
              <AppRoutes />
          </div>
        </AppContext.Provider>
      </ErrorBoundary>
    </>
  );
}

export default App;

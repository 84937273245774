import React from "react";
import ReactDOM from "react-dom";
// import { createRoot } from 'react-dom/client';
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "./fontawesome";
import "./index.css";
import App from "./App";
// import { initSentry } from './libs/errorLib';

// initSentry();

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "lostinthewild",
        endpoint: process.env.REACT_APP_API_URL,
        region: process.env.REACT_APP_API_REGION,
      },
    ],
  },
});

// const container = document.getElementById('app');
// const root = createRoot(container);
// root.render(
//   <Router>
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>
//   </Router>
// );

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
